<template>
    <div class="flex flex-col items-start">
        <slot v-if="$slots.availability" name="availability" />
        <span v-else>
            {{ $t('contact.availability') }}
        </span>

        <a :href="'mailto:' + $t('contact.mail')" class="mon-contact__mail mon-hover-link">
            <span class="mon-status-dot" />
            {{ $t('contact.mail') }}
        </a>
        <a :href="'tel:' + $t('contact.phone').replace(/\s/g, '')" class="mon-hover-link pl-4 lg:pl-7">
            {{ $t('contact.phone') }}
        </a>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.mon-contact__mail {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    margin-top: 0.25rem;

    @include more-than(lg) {
        gap: 1.125rem;
    }
}

</style>
